import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './loader.scss';

function Loader({ className, ...rest }) {
  return (
    <div
      className={classnames('loader', className)}
      {...rest}
    >
      <div className="lds-ring">Loading taux OCDE<div /><div /><div /><div /></div>
    </div>
  );
}

Loader.propTypes = {
  className: PropTypes.string,
};
Loader.defaultProps = {
  className: '',
};
export default React.memo(Loader);
