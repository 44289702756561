import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './footer.scss';
import LinkIcon from '@mui/icons-material/Link';

function Footer({ className, ...rest }) {
  return (
    <div
      className={classnames('footer', className)}
      {...rest}
    >
      <div className="footer-text">
        Calculs approximatifs, à revoir auprès d'un prêteur.

      </div>
      <div className="footer-link">
        Contact par
        <div>
          <a className="footer-link-linkedin" href="mailto:frechenot@gmail.com" target="_blank" rel="noreferrer"> Mail </a>

        </div>
        <div className="footer-link-icon">

          <LinkIcon />
        </div>
        Ou pour me soutenir
        <div>
          <a className="footer-link-tipeee" href="https://fr.tipeee.com/boite-a-outils" target="_blank" rel="noreferrer"> Tipeee </a>

        </div>

      </div>
    </div>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
};
Footer.defaultProps = {
  className: '',
};
export default React.memo(Footer);
