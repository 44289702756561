import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import PropTypes from 'prop-types';
import React from 'react';

function RadioButtonsGroup({ handleChangeFunction }) {
  return (
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label" />
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="ancien"
        name="radio-buttons-group"
        onChange={(event) => handleChangeFunction(event.target.value)}
      >
        <FormControlLabel
          value="neuf"
          control={(
            <Radio
              color="default"
              sx={{ color: '#FFFFFF' }}
            />
)}
          label="Achat dans le neuf"
        />
        <FormControlLabel
          value="ancien"
          control={(
            <Radio
              color="default"
              sx={{ color: '#FFFFFF' }}
            />
)}
          label="Achat dans l'ancien"
        />
      </RadioGroup>
    </FormControl>
  );
}

RadioButtonsGroup.propTypes = {
  handleChangeFunction: PropTypes.func.isRequired,
};

export default React.memo(RadioButtonsGroup);
