import apiAxios from './index';

// eslint-disable-next-line import/prefer-default-export
export async function requestTauxInteret(year, month) {
  try {
    const response = await apiAxios.get(
      `/data/MEI_FIN/IRLT.FRA.M/all?startTime=${year}-${month}&endTime=${year}-${month}&dimensionAtObservation=allDimensions`,
    );
    console.log(response);
    return response;
  }
  catch (err) {
    console.log(err);
    return err;
  }
}
