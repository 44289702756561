const penteNeuf = 55.8108995;
const penteAncien = 14.5264381;
const Bneuf = -103003.94;
const BAncien = -26801.2783;

export default function getFraisDeNotaire(capital, etat) {
  // eslint-disable-next-line no-bitwise
  if (etat === 'neuf') {
    const resultFraisDeNotaire = [Math.round(((capital - (Bneuf)) / penteNeuf))];
    return resultFraisDeNotaire;
  }
  if (etat === 'ancien') {
    const resultFraisDeNotaire = [Math.round(((capital - (BAncien)) / penteAncien))];
    return resultFraisDeNotaire;
  }

  return console.log('error dans les frais de notaire??');
}
