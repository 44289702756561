import EuroIcon from '@mui/icons-material/Euro';
import CalculetteImmo from '../CalculetteImmo/CalculetteImmo';
import Footer from '../Footer/Footer';
import './app.scss';
import myLogo from '../../img/myLogo.svg';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-header__logo">
          <img src={myLogo} alt="mon logo" width="50px" />

        </div>
        <div>
          Calculette Immo

        </div>
        <div className="euroIcon">
          <EuroIcon />
        </div>
      </header>
      <div className="container-composants">
        <CalculetteImmo />
        <div className="App-footer">
          <Footer />

        </div>

      </div>

    </div>
  );
}

export default App;
